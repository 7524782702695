<template>
  <div :style="{'background-image': 'url(' + require('@/assets/cool-background.svg') + ')',}" class="bgimg">
  <!-- <div> -->
    <div class="bottomleft">
      <p><img src="@/assets/homeTitle.png" /></p>
    </div>
    <!-- <div class="middle">
      <h1>{{ msg }}</h1>
      <hr>
      <p>{{error}}</p>
    </div> -->
    <div class="redirectURLs">
      <h4>{{msgHeader}} </h4>
      <ul>
        <li><a href="http://stewardconnectar.org">http://stewardconnectar.org</a></li>
        <li><a href="http://stewardconnectut.org">http://stewardconnectut.org</a></li>
        <li><a href="http://stewardconnectfl.org">http://stewardconnectfl.org</a></li>
        <li><a href="http://stewardconnectla.org">http://stewardconnectla.org</a></li>
        <li><a href="http://stewardconnectma.org">http://stewardconnectma.org</a></li>      
        <li><a href="http://stewardconnectaz.org">http://stewardconnectaz.org</a></li>  
        <li><a href="http://stewardconnectpa.org">http://stewardconnectpa.org</a></li>  
        <li><a href="http://stewardconnectoh.org">http://stewardconnectoh.org</a></li>  
        <li><a href="http://stewardconnecteaston.org">http://stewardconnecteaston.org</a></li>  
        <li><a href="http://stewardconnecttx.org">http://stewardconnecttx.org</a></li>  
      </ul>      
      <hr>
      <!-- <p class="error">{{error}}</p> -->
    </div>
    <div class="bottomleft">
      <p></p>
    </div>
    <div v-if="showModal">
      <Modal :header="header" :text="text" @close="redirectURL"/>
    </div>
  </div>
</template>

<script>
import Modal from './Modal.vue';
// import axios from "axios";

export default {  
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      header: 'Redirecting...',
      text: 'The URL has been changed. Please update your bookmarks to the new URL.',
      showModal: false,
      redirectFlag: false,
      error: "",
      state: "",
      city: "",
      modalTimer: 0,
      msgHeader: "Please allow your location permission for automatic redirect to work properly OR use below URLs for manual navigation:",
      lat: "",
      lng: "",
      statesList: ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA',
                   'ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK',
                   'OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],
      redirectMA: ['MA'],
      redirectAZ: ['AZ'],
      redirectTX: ['AR','LA','TX'],
      redirectUT: ['UT'],   
      redirectFL: ['PA','OH','FL'],
      redirectEaston: ['Easton']
    }
  },
  components: { Modal },
  mounted() {
    /* eslint-disable-next-line no-undef */
    const geocoder = new google.maps.Geocoder();        
         
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          // console.log(position.coords.latitude);
          // console.log(position.coords.longitude);          
          this.geocodeLatLng(geocoder);                        
        },
        error => {
          this.error = "Note:- Please allow your location for redirect to work.";
          this.msgHeader = "Location services were rejected/disabled. Please allow your location permission for redirect to work properly OR use below URLs for manual navigation:";
          // this.error = error.message;
          this.$router.push('/')
          console.log('Error: ', error.message);
        });
      } else {
        console.log("Your browser does not support geolocation API.");
      }    
  },    
  methods: {
    geocodeLatLng(geocoder) {
      const latlng = {
        lat: this.lat,
        lng: this.lng
      };
      //console.log(latlng);
      geocoder
        .geocode({ location: latlng })
        .then((response) => {
          if (response.error_message) {
            this.error = response.error_message;
            console.log(response.error_message);
          } else {
            // this.address = response.results[0].formatted_address;
            if (this.statesList.includes(response.results[0].address_components[4].short_name)) {
              this.state = response.results[0].address_components[4].short_name;
            } else
            {
              this.state = response.results[0].address_components[5].short_name;
            }

            this.city = response.results[0].address_components[2].short_name;
            //test
            // this.state = 'MA';
            // this.city = 'Easton'
            // console.log('Addres:', response.results[0].formatted_address);
            console.log('State:', this.state);
            console.log('City:', this.city);
            // console.log('data:', response.results);
            this.showModal = true;
            // if (this.$route.query.source && this.$route.query.source.includes('iqhealth.com')) 
            // {
            // this.redirect();
            // }
          } 
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },    
    redirectURL() {
      this.showModal = false;
      // console.log(this.$route.query.source)            
      // this.$router.push('/http://localhost:8080')
      if (this.redirectEaston.includes(this.city)) {
        setTimeout(()=>window.location.replace('https://StewardConnecteaston.org'), this.modalTimer) 
      }
      else if (this.redirectMA.includes(this.state)) {
        setTimeout(()=>window.location.replace('https://StewardConnectma.org'), this.modalTimer)
      } 
      else if (this.redirectFL.includes(this.state)) {
        setTimeout(()=>window.location.replace('https://StewardConnectfl.org'), this.modalTimer) 
      }
      else if (this.redirectAZ.includes(this.state)) {
        setTimeout(()=>window.location.replace('https://StewardConnectaz.org'), this.modalTimer) 
      }
      else if (this.redirectTX.includes(this.state)) {
        setTimeout(()=>window.location.replace('https://StewardConnecttx.org'), this.modalTimer) 
      }
      else if (this.redirectUT.includes(this.state)) {
        setTimeout(()=>window.location.replace('https://StewardConnectut.org'), this.modalTimer) 
      }      
      else {
        this.error = "Unable to determine the location."        
        this.msgHeader = "We were unable to determine your location. Please use manual navigation and update your bookmarks to the new URL.";
        this.showModal = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.bgimg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.redirectURLs {
  position: absolute;
  width: 35%;
  top: 150px;
  left: 16px;  
  text-align: left;
  /* background-color:aquamarine;   */
  font-size: 20px;
}

hr {
  margin: 10px auto;
  width: 90%;
}

h4 {
  color: black;    
}

.error {
  color: red;
}

ul {
  margin: auto;  
  list-style-type: none;
   
}

li {
  color: black; 
}
</style>
