import { createRouter, createWebHistory } from "vue-router";
import  Home from '../components/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: {msg: "Coming Soon"}
    }        
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
