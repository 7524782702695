<template>
    <div class="backdrop">
        <div class="modal">
            <h4>{{header}}</h4>
            <p>{{text}}</p>
            <div class="center">
                <button @click="closeModal">Ok</button>
            </div>            
        </div>        
    </div>
</template>

<script>
export default {
    props: ['header', 'text'],
     methods: {
        closeModal() {
            this.$emit('close');
            // console.log('close');
        }
    }
}
</script>

<style>
    .modal {
        width: 500px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 10px;
    }
    .backdrop {
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
    .modal h4 {
        color: #03cfb4;
        border: none;
        padding: 0;
    }
    .modal p {
        font-style: normal;
        color: black;
        font-size: 20px;
    }
    .center {
        width: 20%;
        margin: auto;
    }
    button {
        width: 100%;
        padding: 5px;                
    }
</style>