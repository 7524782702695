<template>
  <!-- <HelloWorld msg="Coming Soon"/>   -->
  <router-view/>
</template>
 
<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}

body, html {
  height: 100%;
  margin: 0;
}
</style>
